import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["ThumbLinkProps", "hoverLabel"];
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { customEvent } from '@playful/telemetry';
import React from 'react';
import { useProject } from '../../hooks/useProject';
import { useRouter } from '../../hooks/useRouter';
import { buildUserRoute } from '../../paths';
import { ProjectThumbnailCard, ProjectThumbnailLabel } from './ProjectThumbnailCard';
export function PreviewThumbLink(_ref) {
  var ThumbLinkProps = _ref.ThumbLinkProps,
    hoverLabel = _ref.hoverLabel,
    props = _objectWithoutProperties(_ref, _excluded);
  var projectId = props.projectId,
    fallbackData = props.fallbackData;
  var _useProject = useProject({
      id: projectId,
      fallbackData: fallbackData
    }),
    projectInfo = _useProject.projectInfo;
  var _useRouter = useRouter(),
    asPath = _useRouter.asPath;
  if (!projectInfo) return null;
  var linkPropsQuery = {
    playerModal: true,
    from: asPath,
    publishedUrl: '',
    projectId: '',
    userName: '',
    slug: ''
  };

  // If the project has a published URL that means it uses a custom domain
  if (projectInfo.publishedUrl) {
    linkPropsQuery = _objectSpread(_objectSpread({}, linkPropsQuery), {}, {
      publishedUrl: projectInfo.publishedUrl,
      projectId: projectInfo.id
    });
  } else {
    linkPropsQuery = _objectSpread(_objectSpread({}, linkPropsQuery), {}, {
      userName: projectInfo.ownerName,
      slug: projectInfo.slug
    });
  }
  return __jsx(ProjectThumbnailCard, _extends({
    hoverLabel: hoverLabel,
    ThumbLinkProps: _objectSpread({
      href: {
        query: linkPropsQuery
      },
      onClick: function onClick() {
        return customEvent('preview-project', {
          project_id: projectInfo.id,
          owner: projectInfo.owner,
          slug: projectInfo.slug
        });
      },
      asPath: buildUserRoute(projectInfo.ownerName, projectInfo.slug),
      shallow: true
    }, ThumbLinkProps)
  }, props));
}
export function PreviewCard(props) {
  var projectId = props.projectId,
    fallbackData = props.fallbackData;
  var _useProject2 = useProject({
      id: projectId,
      fallbackData: fallbackData
    }),
    projectInfo = _useProject2.projectInfo;
  if (!projectInfo) return null;
  return __jsx("div", null, __jsx(PreviewThumbLink, props), __jsx(ProjectThumbnailLabel, null, projectInfo.title));
}